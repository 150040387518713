<template>
  <el-container class="shopList ">
    <el-header class="box add">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>登记管理</el-breadcrumb-item>
        <el-breadcrumb-item v-if="filing_state==2">审核登记</el-breadcrumb-item>
        <el-breadcrumb-item v-if="filing_state==3||filing_state==4">登记详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <el-button class="back_btn" size="medium" plain @click="$router.back()">返回</el-button>
      </div>
    </el-header>
    <el-main class="p15 pt15">
<!--      <img class="type_img" v-if="filing_state==2" src="../../../../assets/image/shenhe.png">-->
<!--      <img class="type_img" v-if="filing_state==3" src="../../../../assets/image/tongguo.png">-->
<!--      <img class="type_img" v-if="filing_state==4" src="../../../../assets/image/bohui.png">-->
      <el-form :inline="true" status-icon ref="form" :model="form" class="demo-ruleForm">
         <el-collapse v-model="activeNames" @change="handleChange" >
      <el-collapse-item title="企业资证上传" name="1">
        <el-row class="box mt20  f14">
           <el-col :span="7" class="tl">
            <el-form-item size="medium" label="营业执照：" class="w" label-width="41%">
              <div class="recordadd-upload">
                <el-image v-if="uploadDisabled" style="width: 160px;height: 160px"
                  :src="fileList[0].url"
                  :preview-src-list="[fileList[0].url]" alt=""></el-image>
                <img v-else src="../../../../assets/image/moren.png" style="width: 160px;height: 160px" />
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="7" class="tl">
            <el-form-item size="medium" :label="form4.company_tag_id==2?'备案证：':'食品经营许可证：'" class="w" label-width="41%">
              <div class="recordadd-upload">
                <el-image v-if="uploadDisabled1" style="width: 160px;height: 160px"
                  :src="fileList[1].url"
                  :preview-src-list="[fileList[1].url]" alt=""></el-image>
                <img v-else src="../../../../assets/image/moren.png" style="width: 160px;height: 160px" />
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-collapse-item>
      <el-collapse-item title="企业基本信息" name="2">
         <el-row class="box mt20  f14">
           <el-col :span="11" class="tl">
            <el-form-item size="medium" label="企业名称：" class="w" label-width="28%">
              <el-input disabled placeholder="企业名称" maxlength='30' clearable v-model="form4.company_name"
                class="input-with-select">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item label="监管类型：" size="medium" class="w" label-width="28%">
              <el-select disabled v-model="form4.company_type_id" filterable>
                <el-option v-for="item in typetOptions" :key="item.company_type_id" :label="item.company_type_name"
                  :value="String(item.company_type_id)"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item size="medium" label="主体类型：" class="w" label-width="28%">
              <el-select disabled v-model="form4.company_tag_id" filterable>
                <el-option v-for="(item,index) in tagOptions" :key="index" :label="item.company_tag_name"
                  :value="String(item.company_tag_id)"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item class="w" label-width="28%" label="统一社会信用代码：" size="medium" >
              <el-input placeholder="请输入统一社会信用代码" disabled clearable
                v-model="form4.credit_code" class="input-with-select">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="form4.company_tag_id==2" :span="11" class="tl">
            <el-form-item class="w" label-width="28%" label="备案证号：" size="medium" >
              <el-input placeholder="备案证号" disabled clearable
                v-model="form4.record_code" class="input-with-select">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col v-else :span="11" class="tl">
            <el-form-item class="w" label-width="28%" label="食品经营许可证号：" size="medium" >
              <el-input placeholder="食品经营许可证号" disabled clearable
                v-model="form4.record_code" class="input-with-select">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="form4.company_tag_id==5" :span="11" class="tl">
            <el-form-item label="企业类型：" size="medium" class="w" label-width="28%">
              <el-input disabled clearable v-model="company_type" class="input-with-select">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="form4.company_tag_id==2" :span="11" class="tl">
            <el-form-item class="w" label-width="28%" label="备案时间：" size="medium">
              <el-date-picker type="date" clearable disabled placeholder="选择日期" :picker-options='pickerOptionsEnd'
                v-model="form4.record_time" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                @change="logTimeChange"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col v-if="form4.company_tag_id==2" :span="11" class="tl">
            <el-form-item class="w" label-width="28%" label="发证日期：" size="medium">
              <el-date-picker type="date" disabled clearable placeholder="选择发证日期" :picker-options='pickerOptionsEnd'
                v-model="form4.issue_time" format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="logTimeChange">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col v-if="form4.company_tag_id==2" :span="11" class="tl">
            <el-form-item class="w" label-width="28%" label="有效期：" size="medium" >
              <el-date-picker type="date" disabled clearable placeholder="选择有效期" v-model="form4.unuseful_time"
                format="yyyy-MM-dd" value-format="yyyy-MM-dd" :picker-options="pickerOptions" @change="logTimeChange">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item class="w" label-width="28%" label="注册地址：" size="medium" >
              <el-input placeholder="请输入注册地址" disabled v-model="form4.produce_address" clearable
                class="input-with-select">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="form4.company_tag_id==5" :span="11" class="tl">
            <el-form-item class="w" label-width="28%" label="发证机关：" size="medium" >
              <el-input placeholder="请输入发证机关" disabled v-model="form4.issue_unit" clearable class="input-with-select">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="form4.company_tag_id==5" :span="11" class="tl">
            <el-form-item class="w" label-width="28%" label="签发日期：" size="medium" >
              <el-date-picker type="date" disabled clearable placeholder="选择签发日期" v-model="form4.issue_time"
                format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="logTimeChange">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item class="w" label-width="28%" label="经营场所面积：" size="medium" >
              <el-input style="width:100px" disabled placeholder="例：20" min="1" clearable v-model="form4.place_area"
                class="input-with-select mr10">
              </el-input>平方米（㎡）
            </el-form-item>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item class="w" label-width="28%" label="从业人数：" size="medium" >
              <el-input placeholder="例：20" disabled v-model="form4.employ_num" clearable class="input-with-select">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="form4.company_tag_id==5" :span="22" class="tl">
            <el-form-item class="w w24" label-width="14%" label="经营范围：" size="medium" >
              <el-checkbox-group v-model="form4.business_names_list"
                @change="handleChecked(3,form4.business_names_list)">
                <el-checkbox-button class="mr10" :disabled="filing_state==3||filing_state==4?true:false"
                  v-for="options in Options3" :label="options.dictionary_field" :key="options.dictionary_field">
                  {{options.dictionary_field}}</el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <el-col  :span="22" class="tl">
            <el-form-item class="w w24" label-width="14%" label="经营储存条件：" size="medium">
              <el-checkbox-group v-model="form4.storage_conditions_list">
                <el-checkbox-button class="mr10" :disabled="filing_state==3||filing_state==4?true:false"
                  v-for="options in Options4" :label="options.dictionary_field" :key="options.dictionary_field">
                  {{options.dictionary_field}}</el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <el-col  :span="22" class="tl">
            <el-form-item class="w w24" label-width="14%" label="经营商品类型：" size="medium">
              <el-checkbox-group v-model="form4.commodity_type_list">
                <el-checkbox-button class="mr10" :disabled="filing_state==3||filing_state==4?true:false"
                  v-for="options in Options5" :label="options.dictionary_field" :key="options.dictionary_field">
                  {{options.dictionary_field}}</el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
          </el-col>

        </el-row>
      </el-collapse-item>
      <el-collapse-item title="法人身份证附件" name="3">
        <el-row class="box mt20  f14">
          <el-col :span="7" class="tl">
            <el-form-item size="medium" label="身份证(头像面)：" class="w" label-width="41%">
              <div class="recordadd-upload">
                <el-image v-if="uploadDisabled2" style="width: 160px;height: 160px"
                  :src="fileList2[0].url"
                  :preview-src-list="[fileList2[0].url]" alt=""></el-image>
                <img v-else src="../../../../assets/image/moren.png" style="width: 160px;height: 160px" />
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="7" class="tl ">
            <el-form-item size="medium" label="身份证(国徽面)：" class="w" label-width="41%" >
              <div class="recordadd-upload">
                 <el-image v-if="uploadDisabled3" style="width: 160px;height: 160px"
                  :src="fileList3[0].url"
                  :preview-src-list="[fileList3[0].url]" alt=""></el-image>
                <img v-else src="../../../../assets/image/moren.png" style="width: 160px;height: 160px" />
              </div>

            </el-form-item>
          </el-col>
        </el-row>
      </el-collapse-item>
      <el-collapse-item title="法人身份信息" name="4">
        <el-row class="box mt20  f14">

           <el-col :span="11" class="tl">
            <el-form-item class="w" label-width="28%" :label="form4.company_tag_id==2?'经营者姓名：':'法人姓名：'" size="medium"
              >
              <el-input :placeholder="form4.company_tag_id==2?'请输入经营者姓名':'请输入法人姓名'" disabled maxlength="10" clearable
                v-model="form4.legal_person_name" class="input-with-select">
              </el-input>
            </el-form-item>
          </el-col>
           <el-col :span="11" class="tl">
        <el-form-item  label="性别：" size="medium" class="w" label-width="28%" >
           <el-select v-model="form4.user_sex" disabled clearable placeholder="性别" >
                <el-option v-for="(item,index) in optionsSex" :key="index" :label="item.label" :value="item.value" ></el-option>
              </el-select>
        </el-form-item>
        </el-col>
        <el-col :span="11" class="tl">
            <el-form-item class="w" label-width="28%" label="民族：" size="medium" >
              <el-input placeholder="请输入民族" disabled maxlength="10" clearable
                v-model="form4.user_nation" class="input-with-select">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item class="w" label-width="28%" label="出生日期：" size="medium" >
              <el-input placeholder="请输入出生日期" disabled maxlength="10" clearable
                v-model="form4.user_born" class="input-with-select">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="22" class="tl">
            <el-row class="box f14">
              <el-form-item class="w" label-width="14%" label="所在地址：" size="medium">
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-input disabled maxlength="10" clearable v-model="form4.user_province" class="input-with-select"> </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item size="medium">
                     <el-input  disabled maxlength="10" clearable v-model="form4.user_city" class="input-with-select"> </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item size="medium">
                     <el-input  disabled maxlength="10" clearable v-model="form4.user_area" class="input-with-select"> </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item size="medium">
                     <el-input  disabled maxlength="10" clearable v-model="form4.user_town" class="input-with-select"> </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-input  disabled maxlength="10" clearable v-model="form4.user_vill" class="input-with-select"> </el-input>
                  </el-form-item>
                </el-col>
              </el-form-item>
            </el-row>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item label="详细地址：" class="w" label-width="28%">
              <el-input disabled placeholder="如道路、门牌号、小区、楼栋号、单元室等（请勿重复录入省市区县信息）" v-model="form4.user_address" size="medium" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item label="有效期：" class="w" label-width="28%">
              <el-input disabled placeholder="请输入有效期" v-model="form4.user_idcard_expirationtime" size="medium" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item label="签发机关：" class="w" label-width="28%">
              <el-input disabled placeholder="请输入签发机关" v-model="form4.user_idcard_government" size="medium" clearable></el-input>
            </el-form-item>
          </el-col>
           <el-col :span="11" class="tl">
            <el-form-item class="w" label-width="28%" label="手机号：" size="medium" >
              <el-input placeholder="请输入11位手机号码" disabled clearable
                v-model="form4.mobilephone" class="input-with-select">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-collapse-item>
       <el-collapse-item title="人员信息" name="5">
         <el-row class="box mt20  f14 people-message">
            <el-table :data="form4.company_employ_list" style="width: 100%" class="no-header">
              <el-table-column prop="company_employ_name"></el-table-column>
               <el-table-column prop="mobilephone"></el-table-column>
              <el-table-column width="180">
                <template slot-scope="scope">
                  <el-image v-if="scope.row.employ_health_url" :src="scope.row.employ_health_url" fit="fill"
                    :preview-src-list="[scope.row.employ_health_url]" style="width: 40px;height: 40px">
                  </el-image>
                  <img v-else src="../../../../assets/image/moren.png" style="width: 40px;height: 40px" />
                </template>
              </el-table-column>
              <el-table-column prop="employ_health_datedue"></el-table-column>
               <el-table-column prop="cold_chain_personnel">
               <template slot-scope="scope">
                 <span v-if="scope.row.cold_chain_personnel=='1'">是</span>
                 <span v-else-if="scope.row.cold_chain_personnel=='2'">否</span>
                </template>
             </el-table-column>
            </el-table>
        </el-row>
      </el-collapse-item>
       <el-collapse-item title="监管部门" name="6" v-if="filing_state==2||filing_state==3">
         <el-row class="box mt20  f14 ">
            <el-col :span="11" class="tl">
            <el-form-item class="w" label-width="28%" label="监管部门：" size="medium" prop="department_id"
              :rules="[{required: true,message: '请选择市场监管局',trigger: 'blur'}]">
              <el-select v-model="form.department_id" :disabled="filing_state==3?true:false" filterable
                placeholder="请选择市监管部门" @change="change1(form.department_id)">
                <el-option v-for="item in options4" :key="item.department_id" :label="item.department_name"
                  :value="String(item.department_id)"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item class="w" label-width="28%" label="监管人员：" size="medium" prop="user_id_manage"
              :rules="[{required: true,message: '请选择监管人员',trigger: 'blur'}]">
              <el-select v-model="form.user_id_manage" :disabled="filing_state==3?true:false" filterable
                placeholder="请选择监管人员">
                <el-option v-for="item in options5" :key="item.user_id" :label="item.user_name"
                  :value="String(item.user_id)"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item class="w" label-width="28%" label="网格人员：" size="medium" >
              <!-- prop="user_id_info"  :rules="[{required: true,message: '请选择网格人员',trigger: 'blur'}]" -->
              <el-select v-model="form.user_id_info" :disabled="filing_state==3?true:false" filterable
                placeholder="请选择网格人员">
                <el-option v-for="item in options6" :key="item.user_id" :label="item.user_name"
                  :value="String(item.user_id)"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-collapse-item>
      <el-collapse-item v-if="filing_state==4" title="驳回原因" name="7" >
         <el-row class="box mt20  f14 people-message">
           <div class="box list">
            <el-col :span="24" class="tl mb20" :offset="1">
              驳回原因：<span class="c333">{{form.unpass_cause}}</span>
            </el-col>
          </div>
        </el-row>
      </el-collapse-item>
     </el-collapse>
        <el-col v-if="filing_state==2" :span="22" class="tl mt20">
          <el-button class="ml_5" size="medium" type="primary" @click="submitForm3('form',1)">审核通过</el-button>
          <el-button class="back_btn" size="medium" type="" @click="timeVisible = true">驳回</el-button>
        </el-col>

      </el-form>
    </el-main>
    <el-dialog title="驳回登记" :visible.sync="timeVisible" :modal-append-to-body="false" width="50%" :show-close="false">
      <el-row class="box f14 c666">
        <div class="box list">
          <el-input type="textarea" :autosize="{ minRows:4, maxRows: 8}" placeholder="如果审核不通过，请填写无效原因"
            v-model="unpass_cause">
          </el-input>
        </div>
        <el-col :span="22" class="tl mt20">
          <el-button class="ml_5" size="medium" type="primary" @click="submitForm3('form',2)">驳回</el-button>
          <el-button class="back_btn" size="medium" type="" @click="timeVisible = false">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </el-container>
</template>
<script>
import Vue from 'vue'
import axios from '../../../../util/api'
import { local, aa, objKeySort,session } from '../../../../util/util'
import { imageUrl } from '../../../../config/index'
import { mapState } from 'vuex'
import md5 from 'js-md5';
export default {
  data () {
    return {
      imageUrl: imageUrl,
      filing_state: '',
      company_name: '',
      credit_code:'',
      activeNames: ['1','2','3','4','5','6','7'],
      optionsSex:[{label: '男',value:'1'},{label: '女',value:'2'}],
      company_type: '',
      user: '',
      unpass_cause: '',
      timeVisible: false,
      uploadDisabled: false,
      uploadDisabled1: false,
      uploadDisabled2: false,
      uploadDisabled3: false,
      uploadDisabled4: false,
      uploadDisabled11: false,
      timer: '',
      loading: false,
      tableData: [],
      show: true,
      count: '',
      typetOptions: [],
      tagOptions: [],
      Options1: [],//主营项目
      Options2: [],//兼营项目
      Options3: [],//经营范围
      Options4: [],//储存条件
      Options5: [],//商品类型
      fileList: [],
      fileList1: [],
      fileList2: [],
      fileList3: [],
      fileList4: [],
      form4: {
        company_id: '',
        company_name: '',
        company_type_id: '',
        company_tag_id: '',
        legal_person_name: '',
        mobilephone: '',
        idcard: '',
        record_time: '',
        record_code: '',
        issue_time: '',
        unuseful_time: '',
        place_area: '',
        employ_num: '',
        credit_code: '',
        issue_unit: '',
        produce_address: '',
        produce_form_names_list: [],//生产形式
        produce_type_names_list: [],//生产类别
        main_subject_names_list: [],//主营项目
        sub_subject_names_list: [],//兼营项目
        storage_conditions_list: [],//储存条件
        commodity_type_list: [],//商品类型
        business_names_list: [],//经营范围
        accessory_list: [{ accessory_type: '营业执照', accessory_url: '' }, { accessory_type: '备案证', accessory_url: '' }, { accessory_type: '法人身份证', accessory_url: '' }, { accessory_type: '身份证国徽', accessory_url: '' }],
        company_employ_list: [],
        user_idcard_expirationtime:'',
        user_idcard_government:'',
        user_sex:'',
        user_nation:'',
        user_born:'',
        user_province:'',
        user_city:'',
        user_area:'',
        user_town:'',
        user_vill:'',
        user_address:'',

      },
      form: {
        company_id: '',
        department_id: '',
        filing_state: '',
        unpass_cause: '',
        user_id_manage: '',
        user_id_info: ''
      },
      options4: '',
      options5: '',
      options6: '',
      dialogImageUrl: '',
      dialogVisible: false,
      pickerOptionsEnd: {
        disabledDate: time => {
          return time.getTime() > Date.now()
        }
      },
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now();
        },
      }
    }
  },
  created () {
    var that = this;
    that.user = local.get('user');
    this.form4.company_id =session.get('params').id?String(session.get('params').id):'';
    that.form.company_id = session.get('params').id?String(session.get('params').id):'';
    that.getData({ company_id: this.form4.company_id })
  },
  methods: {
    handleChange (val) {
    },
    getData (params) {
      var that = this;
      axios.get('/pc/department/all').then((v) => { this.options4 = v.data.department_list })
      axios.get('/pc/company/single', params).then((v) => {
        that.filing_state = v.data.company.filing_state;
        that.form4.company_type_id = String(v.data.company.company_type_id);
        that.form4.company_tag_id = String(v.data.company.company_tag_id);
        that.form4.company_id = String(v.data.company.company_id);
        that.form.unpass_cause = v.data.company.unpass_cause
        that.form4.company_name = v.data.company.company_name;
        that.credit_code= v.data.company.credit_code;
        that.company_type = v.data.company.company_type;
        that.form.department_id = v.data.company.department_id;
        that.form.user_id_manage = v.data.company.user_id_manage
        that.form.user_id_info = v.data.company.user_id_info;
        that.company_name = v.data.company.company_name;
        this.getType()
        this.getTag(that.form4.company_type_id);
        this.getDictionary('4')
        this.getcompanyDetail(params)

        if (that.filing_state == 3) { that.change1(that.form.department_id) }
      })
    },
    change1 (item) {
      axios.get('/pc/department-user/list', { department_id: String(item), user_tag: '1', pager_openset: '1000' }).then((v) => { this.options5 = v.data.department_user_list })
      axios.get('/pc/department-user/list', { department_id: String(item), user_tag: '2', pager_openset: '1000' }).then((v) => { this.options6 = v.data.department_user_list })
    },
    getcompanyDetail (params) {
      var that = this;
      axios.get('/pc/company-circulate/single', params).then((v) => {
        that.form4 = v.data.companyCirculate;
        that.form4.company_type_id = String(v.data.companyCirculate.company_type_id);
        that.form4.company_tag_id = String(v.data.companyCirculate.company_tag_id);
        that.form4.company_id = String(v.data.companyCirculate.company_id);
that.form4.company_name =that.company_name
 that.form4.credit_code= that.credit_code;
        let aaa = v.data.companyCirculate.company_employ_list;
        let bbb = v.data.companyCirculate.accessory_list;
        that.form4.company_employ_list = [];
        that.form4.accessory_list = [];
        aaa.forEach(item => {
          that.form4.company_employ_list.push(objKeySort({
            employ_health_datedue: item.employ_health_datedue,
            employ_health_url: item.employ_health_url,
            company_employ_name: item.company_employ_name,
              cold_chain_personnel:item.cold_chain_personnel,
              mobilephone:item.mobilephone
          }));
        })
        bbb.forEach((item, index) => {
          if (item.accessory_url != "") {
            if (item.accessory_type === "营业执照") { this.fileList = [{ name: item.accessory_type, url: item.accessory_url }]; this.uploadDisabled = true }
            if (item.accessory_type === "食品经营许可证") { this.fileList1 = [{ name: item.accessory_type, url: item.accessory_url }]; this.uploadDisabled1 = true }
            if (item.accessory_type === "法人身份证正面") { this.fileList2 = [{ name: item.accessory_type, url:item.accessory_url }]; this.uploadDisabled2 = true }
            if (item.accessory_type === "法人身份证背面") { this.fileList3 = [{ name: item.accessory_type, url:  item.accessory_url }]; this.uploadDisabled3 = true }
          }
          that.form4.accessory_list.push(objKeySort({
            accessory_type: item.accessory_type,
            accessory_url: item.accessory_url,
          }));
        })
      })
    },
    logTimeChange (val) { },
    getType () {
      var that = this;
      axios.get('/pc/company-type/all').then((v) => {
        that.typetOptions = v.data.company_type_list;
      })
    },
    getTag (params) {
      var that = this;
      axios.get('/pc/company-tag/all', { company_type_id: params }).then((v) => {
        that.tagOptions = v.data.company_tag_list;
      })
    },
    getDictionary (params) {
      var that = this;
      axios.get('/pc/dictionary/all', { company_tag_id: params }).then((v) => {
        that.Options1 = v.data.dictionary_list.经营信息.主营项目;
        that.Options2 = v.data.dictionary_list.经营信息.兼营项目;
        that.Options3 = v.data.dictionary_list.经营信息.经营范围;
        that.Options4 = v.data.dictionary_list.经营信息.储存条件;
        that.Options5 = v.data.dictionary_list.经营信息.商品类型;
      })
    },
    submitForm3 (form,index) {
      this.$refs[form].validate((valid) => {
        if (valid) {
           if (index == 2) {
            this.form.filing_state = '4';
            this.form.unpass_cause = this.unpass_cause
           } else {
            this.form.filing_state = '3'
           }
         axios.put('/pc/company/update', this.form).then((v) => {
           this.filing_state = this.form.filing_state;
           this.$router.back()
          })
        }})

    },
  }
}
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .active {
    background: #409eff;
    border-color: #409eff;
    color: #ffffff;
  }
  .type_img {
    position: absolute;
    right: 10%;
    width: 80px;
    z-index: 100;
    top: 15%;
  }
  .back_btn {
    background: rgba(247, 248, 249, 1);
    border: 1px solid rgba(217, 222, 225, 1);
  }
  .recordadd-title {
    height: 34px;
    line-height: 34px;
    border-left: 2px solid #788287;
  }
  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
  /deep/ .el-checkbox-button__inner {
    border-left: 1px solid #dcdfe6;
    border-radius: 4px !important;
  }
  /deep/ .el-table.no-header .el-table__header {
    display: none;
  }
  .people-message .el-form-item {
    margin-bottom: 0px;
  }
  /deep/ .el-col-7 .el-form-item__content {
    width: 160px !important;
  }
  .recordadd-upload {
    width: 160px;
    height: 160px;
    float: left;
    margin-right: 10px;
    .disabled {
      height: 100%;
    }
    /deep/ .el-upload {
      width: 160px;
      height: 160px;
      line-height: 160px;
    }
    /deep/ .el-upload-list--picture-card .el-upload-list__item {
      width: 160px;
      height: 160px;
    }
  }
  .health_url {
    /deep/ .el-upload--picture-card {
      width: 70px;
      height: 40px;
      background-color: transparent;
      border: none;
      .el-button--medium {
        position: absolute;
        left: 10px;
      }
    }
    /deep/ .el-upload-list--picture-card .el-upload-list__item {
      width: 70px;
      height: 40px;
    }
  }
  table .cell .is-error {
    padding-bottom: 20px;
  }
  .el-table th.is-leaf,
  .el-table td {
    border-bottom: none;
  }
  /deep/ .disabled .el-upload--picture-card {
    display: none !important;
  }
  /deep/ .el-table {
    .el-table__body,
    .el-table__header {
      width: 100% !important;
    }
  }
  /deep/ .el-checkbox-button.is-checked .el-checkbox-button__inner {
    background-color: #409eff;
    border-color: #409eff;
    color: #fff;
  }
  .el-checkbox-button {
    margin-bottom: 10px;
  }
}
</style>
